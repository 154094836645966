<template>
  <div>
    <v-card
      v-if="isiten()"
      :key="keyform"
      outlined
      :width="getSize()"
      class="transparent"
    >
      <iframe name="dummyframe" id="dummyframe" style="display: none"></iframe>
      <div v-if="!color1">
        <v-card-title
          v-if="!sameses"
          class="justify-center text-center white--text meticula"
        >
          <div class="hidden-md-and-down" v-if="!submitted">
            <span style="line-height: 0px"
              >Fill Your Information <br />
              & We Will Contact You
            </span>
          </div>
        </v-card-title>
      </div>
      <form
        class="pt-0"
        action="https://docs.google.com/forms/u/2/d/e/1FAIpQLScOIi81eQ7BbvliskmDu2o_ZsFc8w3EAc_n50ubw9atBkyKZQ/formResponse"
        method="POST"
        v-if="!sameses"
        target="dummyframe"
        id="theForm"
        name="theForm"
      >
        <div class="pt-0" :hidden="submitted">
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :counter="20"
            label="Name"
            jsname="YPqjbf"
            name="entry.1519658856"
            class="contentborder pt-0"
            :hidden="submitted"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="phone"
            :error-messages="phoneErrors"
            :counter="10"
            label="Phone Number"
            name="entry.1339993681"
            class="contentborder"
            :hidden="submitted"
            required
            @input="$v.phone.$touch()"
            @blur="$v.phone.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            name="entry.852294895"
            class="contentborder"
            :hidden="submitted"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
          <input
            hidden
            type="text"
            id="lname"
            name="entry.22946558"
            :value="valForm"
          />
        </div>
        <div>
          <div v-if="submitted">
            <br />
            <br />
            <br />
            <br class="hidden-sm-and-down" />
            <h3 :class="color22()">Thank You For Your Submission</h3>
          </div>
          <br class="hidden-md-and-down" />
          <div class="hidden-md-and-down">
            <v-btn
              rounded
              depressed
              @click="sub"
              :hidden="submitted"
              :class="color12()"
              >SUBMIT</v-btn
            >
          </div>
          <div class="hidden-md-and-up">
            <v-btn
              rounded
              depressed
              @click="sub"
              width="113"
              style="left: 52px"
              :hidden="submitted"
              :class="color12()"
              >SUBMIT</v-btn
            >
            <v-btn
              rounded
              depressed
              v-if="color1"
              @click="sub"
              width="113"
              :hidden="submitted"
              class="hidden-md-and-up cyan white--text bigger-brn"
              >Submit</v-btn
            >
          </div>
          <br class="hidden-md-and-down" />
          <br class="hidden-md-and-down" />
        </div>
      </form>
      <div v-if="sameses">
        <br />
        <br />
        <br />
        <br />
        <br class="hidden-sm-and-down" />

        <v-card outlined class="transparent" align="center">
          <h3>Sorry, Only One Submission Per Session Allowed</h3>
        </v-card>
      </div>
    </v-card>

    <v-card
      v-if="!isiten()"
      :key="keyform"
      outlined
      :width="getSize()"
      class="transparent"
    >
      <iframe name="dummyframe" id="dummyframe" style="display: none"></iframe>
      <div v-if="!color1">
        <v-card-title
          v-if="!sameses"
          class="justify-center text-center white--text meticula"
        >
          <div class="hidden-md-and-down" v-if="!submitted">
            <span style="line-height: 0px"
              >Fill Your Information <br />
              & We Will Contact You
            </span>
          </div>
        </v-card-title>
      </div>
      <form
        class="pt-0"
        action="https://docs.google.com/forms/u/2/d/e/1FAIpQLScOIi81eQ7BbvliskmDu2o_ZsFc8w3EAc_n50ubw9atBkyKZQ/formResponse"
        method="POST"
        v-if="!sameses"
        target="dummyframe"
        id="theForm"
        name="theForm"
      >
        <div class="pt-0" :hidden="submitted">
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :counter="20"
            label="Name"
            jsname="YPqjbf"
            name="entry.1519658856"
            class="contentborder pt-0"
            :hidden="submitted"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="phone"
            :error-messages="phoneErrors"
            :counter="10"
            label="Phone Number"
            name="entry.1339993681"
            class="contentborder"
            :hidden="submitted"
            required
            @input="$v.phone.$touch()"
            @blur="$v.phone.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            name="entry.852294895"
            class="contentborder"
            :hidden="submitted"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
          <input
            hidden
            type="text"
            id="lname"
            name="entry.22946558"
            :value="valForm"
          />
        </div>
        <div>
          <div v-if="submitted">
            <br />
            <br />
            <br />
            <br class="hidden-sm-and-down" />
            <h3 :class="color22()">Thank You For Your Submission</h3>
          </div>
          <br class="hidden-md-and-down" />
          <div class="hidden-md-and-down">
            <v-btn
              rounded
              depressed
              @click="sub"
              :hidden="submitted"
              :class="color12a()"
              style="display: block; margin-left: auto; margin-right: auto"
              >SUBMIT</v-btn
            >
          </div>
          <div class="hidden-md-and-up">
            <v-btn
              rounded
              depressed
              @click="sub"
              width="113"
              style="left: 52px"
              :hidden="submitted"
              :class="color12a()"
              >SUBMIT</v-btn
            >
            <v-btn
              rounded
              depressed
              v-if="color1"
              @click="sub"
              width="113"
              :hidden="submitted"
              class="hidden-md-and-up cyan white--text bigger-brn"
              >Submit</v-btn
            >
          </div>
          <br class="hidden-md-and-down" />
          <br class="hidden-md-and-down" />
        </div>
      </form>
      <div v-if="sameses">
        <br />
        <br />
        <br />
        <br />
        <br class="hidden-sm-and-down" />

        <v-card outlined class="transparent" align="center">
          <h3>Sorry, Only One Submission Per Session Allowed</h3>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  email,
  minLength,
  numeric,
} from "vuelidate/lib/validators";

export default {
  props: ["valForm", "color1"],
  mixins: [validationMixin],
  validations: {
    name: { required, maxLength: maxLength(20) },
    phone: {
      required,
      numeric,
      maxLength: maxLength(10),
      minLength: minLength(7),
    },
    email: { required, email },
    select: { required },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data: () => ({
    name: "",
    email: "",
    phone: "",
    extra: "",
    select: null,
    action: "",
    items: ["One-Page Website", "Resume Website", "Full Website", "Other"],
    checkbox: false,
    submitted: false,
    sameses: sessionStorage["submitted"],
    submited: { name: "", email: "", select: "" },
    keyform: 0,
    windowWidth: window.innerWidth,
    trueval: true,
  }),

  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 10 characters long");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.numeric && errors.push("Must not contain Letters");
      !this.$v.phone.minLength &&
        errors.push("Phone Must be at least 7 numbers long");
      !this.$v.phone.maxLength &&
        errors.push("Phone must be at most 10 characters long");
      !this.$v.phone.required && errors.push("Phone is required.");
      return errors;
    },
  },

  methods: {
    sub() {
      if (this.name && this.email && this.phone) {
        document.getElementById("theForm").submit();
      } else {
        this.$v.$touch();
      }
      // this.keyform += 1;

      // console.log(document.getElementById("theForm"));
    },
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
    getForm() {},
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.checkbox = false;
    },
    getSize() {
      if (this.windowWidth < 1200) {
        return "280";
      } else {
        return "800";
      }
    },
    color12() {
      if (this.color1) {
        return "cyan white--text center1 bigger-btn hidden-md-and-down";
      } else {
        return "white cyan--text center1 btnstyle";
      }
    },
    color12a() {
      if (this.color1) {
        return "cyan white--text bigger-btn hidden-md-and-down";
      } else {
        return "white cyan--text  btnstyle";
      }
    },
    color22() {
      if (this.color1) {
        return "justify-center text-center cyan--text meticula";
      } else {
        return "justify-center text-center white--text meticula";
      }
    },
  },
};
</script>
<style scoped>
h3 {
  font-display: none !important;
  font-family: meticula !important;
  font-size: calc(1.5rem);
  font-weight: lighter;
}

.meticula {
  font-family: meticula !important;
  font-size: calc(1.5rem);
  font-weight: lighter;
}
.center1 {
  width: 40%;
  left: 30%;
}

.contentborder {
  border-style: none none none none;
  border-width: 1px;
}

.bigger-btn {
  text-transform: none !important;
  font-size: 1rem;
  top: -8px;
  font-family: meticula;
}

.bigger-brn {
  top: 12px;
}
</style>
