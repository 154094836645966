<template>
  <div>
    <div v-if="isiten()">
      <v-card outlined class="transparent" :style="styleCard()" align="center">
        <v-parallax :class="getCP()" :height="getHeight()" :src="mOrT()">
          <v-toolbar flat color="transparent">
            <v-toolbar-items>
              <v-card
                outlined
                class="transparent"
                style="margin-right: -16px"
              ></v-card>
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="slideX"
              >
                <v-card outlined class="transparent hidden-md-and-up">
                  <v-card
                    outlined
                    class="transparent"
                    :height="getHeight() - 220"
                  ></v-card>
                  <v-card
                    outlined
                    style="left: 0.1vh"
                    class="mt-0 meticula-title-m text-left transparent greytext"
                  >
                    <v-card-title
                      class="meticula-title-m pt-7 pb-0 text-left greytext"
                      >Practising The Art</v-card-title
                    >
                    <v-card height="1" outlined class="transparent"></v-card>
                    <v-card height="1" outlined class="transparent"></v-card>
                    <v-card-title
                      width="500"
                      class="meticula-title-m pt-0 pb-7 text-left greytext"
                      >of Preserving Beauty</v-card-title
                    >

                    <v-btn
                      depressed
                      rounded
                      class="
                        mb-2
                        hidden-md-and-up
                        cyan
                        text-transform-none
                        meticula-small-btn
                      "
                      style="left: 3%; top: -1.5vh; font-weight: lighter"
                      @click="overlay = !overlay"
                      >Book Your Complimentary Consultation Now!</v-btn
                    >
                  </v-card>
                </v-card>
              </v-lazy>
            </v-toolbar-items>

            <v-toolbar-items>
              <v-card
                outlined
                class="transparent"
                :width="windowWidth / 18"
              ></v-card>
            </v-toolbar-items>
          </v-toolbar>
          <v-card
            flat
            class="transparent hidden-sm-and-down"
            :height="windowHeight / 10"
            :width="windowWidth"
          ></v-card>
          <br />
          <v-card-title>
            <v-card
              outlined
              class="transparent"
              :width="windowWidth / 13.6"
            ></v-card>

            <v-lazy
              :options="{
                threshold: 0.5,
              }"
              transition="slideX"
            >
              <v-card outlined :class="getClassText2()" width>
                <br class="hidden-sm-and-down" />
                <br class="hidden-sm-and-down" />
                <br class="hidden-sm-and-down" />
                <br class="hidden-sm-and-down" />

                <v-card
                  align="left"
                  outlined
                  class="hidden-sm-and-down greytext transparent"
                >
                  <div>Practising The Art</div>
                  <div>of Preserving Beauty</div>
                </v-card>
              </v-card>
            </v-lazy>
          </v-card-title>
          <v-lazy
            :options="{
              threshold: 0.5,
            }"
            transition="slideX2"
            v-if="!isMobile()"
          >
            <v-card-title>
              <v-card
                outlined
                class="transparent"
                :width="windowWidth / 13.6"
              ></v-card>

              <v-btn
                depressed
                rounded
                min-width="680"
                min-height="60"
                class="
                  hidden-sm-and-down
                  mt-0
                  cyan
                  white--text
                  text-transform-none
                  meticula-small
                "
                @click="overlay = !overlay"
                style="top: -2vh"
                >Book Your Complimentary Consultation Now!</v-btn
              >
            </v-card-title>
          </v-lazy>

          <v-card
            height="400"
            :min-width="windowWidth"
            outlined
            class="paraop transparent hidden-md-and-down"
          ></v-card>
        </v-parallax>

        <v-flex xs12 md12>
          <v-card outlined class="transparent">
            <v-card outlined class="transparent"></v-card>
            <v-card-title v-if="!isMobile()" class="my-style justify-center">
              <v-card outlined class="transparent"></v-card>

              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                min-height="100"
                transition="slide-x-transition"
              >
                <v-card
                  v-if="getCard1()"
                  outlined
                  class="transparent cyan--text"
                  min-width="492"
                >
                  <br />
                  <br />
                  <v-card-title class="justify-center meticula-big"
                    >OUR SERVICES</v-card-title
                  >
                  <br class="hidden-lg-and-down" />
                  <div class="mt-2 hidden-xl-only"></div>
                  <v-card-title class="greytext2 meticula-medium"
                    >THE AREAS OF OUR PRACTICE</v-card-title
                  >
                </v-card>
              </v-lazy>
            </v-card-title>

            <v-card v-if="!getCard1()" outlined class="transparent">
              <v-card-title
                class="mt-6 justify-center meticula-title cyan--text"
                >Our Services</v-card-title
              >
            </v-card>

            <v-flex xs11 sm12 md12 xl12 lg12>
              <v-row class="pa-auto ma-auto">
                <v-spacer></v-spacer>
                <v-col v-for="(item, index) in serviceCards" :key="index">
                  <v-card outlined class="pl-auto transparent" min-width="300">
                    <router-link v-if="!isMobile()" :to="item.route">
                      <v-img
                        width="25vw"
                        height="25vw"
                        :src="item.img"
                        :lazy-src="item.img"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0">
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </router-link>

                    <div class="text-center">
                      <v-img
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        v-if="isMobile()"
                        :src="item.imgm"
                        :lazy-src="item.imgm"
                        @click="item.click = !item.click"
                      >
                        <h1
                          width="300"
                          :style="item.stylef"
                          :class="item.classm"
                        >
                          {{ item.textm }}
                        </h1>
                      </v-img>

                      <v-card
                        v-if="item.click"
                        outlined
                        align="center"
                        class="mt-5 transparent"
                      >
                        <v-card
                          class="pb-4 pt-6"
                          align="center"
                          flat
                          :style="item.style"
                          color="#00abc8"
                        >
                          <div
                            class="pb-1"
                            v-for="(item, index) in item.items"
                            :key="index"
                          >
                            <router-link
                              style="
                                font-family: meticula;
                                text-decoration: none;
                                color: white;
                                font-size: 18px;
                                line-spacing: 10px;
                              "
                              :to="item.ro"
                            >
                              <span>{{ item.title }} </span>
                            </router-link>
                          </div>
                        </v-card>
                      </v-card>
                    </div>

                    <router-link :to="item.route" class="nodec">
                      <v-card-title
                        v-if="!isMobile()"
                        width="200"
                        class="
                          justify-center
                          pb-3
                          text-justify-center
                          mt-3
                          meticula-med
                          cyan--text
                        "
                      >
                        <div>{{ item.text }}</div>
                      </v-card-title>

                      <v-card-title
                        v-if="!isMobile()"
                        style="top: 10%; line-height: 50%"
                        class="
                          justify-center
                          pt-0
                          text-justify-center
                          meticula-med
                          cyan--text
                        "
                      >
                        <div>{{ item.text2 }}</div>
                      </v-card-title>
                    </router-link>
                  </v-card>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <br class="hidden-sm-and-down" />
            </v-flex>
            <v-card></v-card>
          </v-card>

          <br />
          <br class="hidden-sm-and-down" />
        </v-flex>
        <v-card
          align="center"
          style="top: -10%"
          height="150"
          outlined
          class="hidden-md-and-up transparent"
        >
          <a class="nodec" href="tel:+962799451777">
            <v-btn
              width="200"
              class="mt-2 pt-1 cyan white--text text-transform-none call-btn"
              rounded
              depressed
              >Call Now!</v-btn
            >
          </a>
          <div>
            <br />
            <a
              class="nodec"
              target="_blank"
              href="https://www.facebook.com/ShamiDermaa/"
            >
              <v-icon x-large color="cyan" class="text-transform-none mx-2"
                >mdi-facebook</v-icon
              >
            </a>
            <a
              class="nodec"
              target="_blank"
              href="https://www.instagram.com/shamiderma/"
            >
              <v-icon x-large color="cyan" class="mx-2">mdi-instagram</v-icon>
            </a>
          </div>
        </v-card>
      </v-card>
      <v-overlay :value="overlay" @click="overlay = false">
        <v-card
          color="#00abc8"
          class="rounded-card hidden-md-and-down"
          max-width="500"
          min-height="400"
        >
          <v-card outlined class="rounded-card transparent pa-8">
            <v-icon depressed @click="overlay = false" class="center6" dark
              >mdi-window-close</v-icon
            >
            <br />
            <Form valForm="HomePage"></Form>
          </v-card>
        </v-card>
        <v-card
          color="#00abc8"
          class="rounded-card hidden-md-and-up"
          max-width="500"
          height="346"
        >
          <v-card outlined class="rounded-card transparent pl-5 pr-5 pt-1 pb-0">
            <v-card
              style="top: 24px"
              align="center"
              outlined
              class="transparent"
            >
              <div class="hidden-md-and-up" style="al">
                <span style="font-size: 20px; line-height: 0px"
                  >Fill Your Information & <br />
                  We Will Contact You
                </span>
              </div>
            </v-card>
            <v-icon
              depressed
              @click="overlay = false"
              class="center6 hidden-md-and-down"
              dark
              >mdi-window-close</v-icon
            >

            <Form class="pl-8 pr-8" valForm="HomePage"></Form>
            <v-icon
              depressed
              @click="overlay = false"
              class="center6 hidden-md-and-up"
              style="top: -305px; left: -10px; font-size: 20px"
              dark
              >mdi-window-close
            </v-icon>
          </v-card>
        </v-card>
      </v-overlay>
    </div>
    <div style="font-family: ar !important" v-if="!isiten()">
      <v-card outlined class="transparent" :style="styleCard()" align="center">
        <v-parallax :class="getCP()" :height="getHeight()" :src="mOrT()">
          <v-toolbar flat color="transparent">
            <v-toolbar-items>
              <v-card
                outlined
                class="transparent"
                style="margin-right: -16px"
              ></v-card>
              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                transition="slideX"
              >
                <v-card outlined class="transparent hidden-md-and-up">
                  <v-card
                    outlined
                    class="transparent"
                    :height="getHeight() - 220"
                  ></v-card>
                  <v-card
                    outlined
                    style="left: 0.1vh"
                    class="mt-0 meticula-title-m text-left transparent greytext"
                  >
                    <v-card-title
                      class="
                        meticula-title-m
                        pt-7
                        pb-0
                        text-left
                        greytext
                        artext
                      "
                      >ممارسة فن</v-card-title
                    >
                    <v-card height="1" outlined class="transparent"></v-card>
                    <v-card height="1" outlined class="transparent"></v-card>
                    <v-card-title
                      width="500"
                      class="
                        meticula-title-m
                        pt-0
                        pb-7
                        text-left
                        greytext
                        artext
                      "
                      >الحفاظ على الجمال</v-card-title
                    >

                    <v-btn
                      depressed
                      rounded
                      class="
                        mb-2
                        hidden-md-and-up
                        cyan
                        text-transform-none
                        meticula-small-btn
                        artext
                      "
                      style="left: 3%; top: -1.5vh; font-weight: lighter"
                      @click="overlay = !overlay"
                      >احجز استشارتك المجانية الآن!</v-btn
                    >
                  </v-card>
                </v-card>
              </v-lazy>
            </v-toolbar-items>

            <v-toolbar-items>
              <v-card
                outlined
                class="transparent"
                :width="windowWidth / 18"
              ></v-card>
            </v-toolbar-items>
          </v-toolbar>
          <v-card
            flat
            class="transparent hidden-sm-and-down"
            :height="windowHeight / 10"
            :width="windowWidth"
          ></v-card>
          <br />
          <v-card-title>
            <v-card
              outlined
              class="transparent"
              :width="windowWidth / 13.6"
            ></v-card>

            <v-lazy
              :options="{
                threshold: 0.5,
              }"
              transition="slideX"
            >
              <v-card outlined :class="getClassText2()" width>
                <br class="hidden-sm-and-down" />
                <br class="hidden-sm-and-down" />
                <br class="hidden-sm-and-down" />
                <br class="hidden-sm-and-down" />

                <v-card
                  align="right"
                  outlined
                  class="hidden-sm-and-down greytext transparent artext"
                >
                  <div>ممارسة فن</div>
                  <div>الحفاظ على الجمال</div>
                </v-card>
              </v-card>
            </v-lazy>
          </v-card-title>
          <v-lazy
            :options="{
              threshold: 0.5,
            }"
            transition="slideX2"
            v-if="!isMobile()"
          >
            <v-card-title>
              <v-card
                outlined
                class="transparent"
                :width="windowWidth / 13.6"
              ></v-card>

              <v-btn
                depressed
                rounded
                min-width="680"
                min-height="60"
                class="
                  hidden-sm-and-down
                  mt-0
                  cyan
                  white--text
                  text-transform-none
                  meticula-small
                  artext
                "
                @click="overlay = !overlay"
                style="top: -2vh"
                >احجز استشارتك المجانية الآن!</v-btn
              >
            </v-card-title>
          </v-lazy>

          <v-card
            height="400"
            :min-width="windowWidth"
            outlined
            class="paraop transparent hidden-md-and-down"
          ></v-card>
        </v-parallax>

        <v-flex xs12 md12>
          <v-card outlined class="transparent">
            <v-card outlined class="transparent"></v-card>
            <v-card-title v-if="!isMobile()" class="my-style justify-center">
              <v-card outlined class="transparent"></v-card>

              <v-lazy
                :options="{
                  threshold: 0.5,
                }"
                min-height="100"
                transition="slide-x-transition"
              >
                <v-card
                  v-if="getCard1()"
                  outlined
                  class="transparent cyan--text"
                  min-width="492"
                  align="center"
                >
                  <br />
                  <br />
                  <v-card-title class="justify-center meticula-big artext"
                    >خدماتنا</v-card-title
                  >
                  <br class="hidden-lg-and-down" />
                  <div class="mt-2 hidden-xl-only"></div>
                  <v-card-title class="greytext2 meticula-medium artext">
                    <div
                      style="
                        display: block;
                        margin-right: auto;
                        margin-left: auto;
                      "
                    >
                      مجالات ممارستنا
                    </div></v-card-title
                  >
                </v-card>
              </v-lazy>
            </v-card-title>

            <v-card v-if="!getCard1()" outlined class="transparent">
              <v-card-title
                class="mt-6 justify-center meticula-title cyan--text artext"
                >خدماتنا</v-card-title
              >
            </v-card>

            <v-flex xs11 sm12 md12 xl12 lg12>
              <v-row class="pa-auto ma-auto">
                <v-spacer></v-spacer>
                <v-col v-for="(item, index) in serviceCards" :key="index">
                  <v-card outlined class="pl-auto transparent" min-width="300">
                    <router-link v-if="!isMobile()" :to="item.route">
                      <v-img
                        width="25vw"
                        height="25vw"
                        :src="item.img"
                        :lazy-src="item.img"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0">
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </router-link>

                    <div class="text-center">
                      <v-img
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        v-if="isMobile()"
                        :src="item.imgm"
                        :lazy-src="item.imgm"
                        @click="item.click = !item.click"
                      >
                        <h1
                          width="300"
                          :style="
                            item.stylef +
                            ' position: absolute !important; left: 100px;'
                          "
                          :class="item.classm + ' artext'"
                        >
                          {{ item.textmar }}
                        </h1>
                      </v-img>

                      <v-card
                        v-if="item.click"
                        outlined
                        align="center"
                        class="mt-5 transparent artext"
                      >
                        <v-card
                          class="pb-4 pt-6"
                          align="center"
                          flat
                          :style="item.style"
                          color="#00abc8"
                        >
                          <div
                            class="pb-1"
                            v-for="(item, index) in item.items"
                            :key="index"
                          >
                            <router-link
                              style="
                                font-family: meticula;
                                text-decoration: none;
                                color: white;
                                font-size: 18px;
                                line-spacing: 10px;
                              "
                              :to="item.ro"
                            >
                              <span>{{ item.title }} </span>
                            </router-link>
                          </div>
                        </v-card>
                      </v-card>
                    </div>

                    <router-link :to="item.route" class="nodec">
                      <v-card-title
                        v-if="!isMobile()"
                        width="200"
                        class="
                          justify-center
                          pb-3
                          text-justify-center
                          mt-3
                          meticula-med
                          cyan--text
                          artext
                        "
                      >
                        <div>{{ item.texta }}</div>
                      </v-card-title>

                      <v-card-title
                        v-if="!isMobile()"
                        style="top: 10%; line-height: 50%"
                        class="
                          justify-center
                          pt-0
                          text-justify-center
                          meticula-med
                          cyan--text
                          artext
                        "
                      >
                        <div>{{ item.text2a }}</div>
                      </v-card-title>
                    </router-link>
                  </v-card>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <br class="hidden-sm-and-down" />
            </v-flex>
            <v-card></v-card>
          </v-card>

          <br />
          <br class="hidden-sm-and-down" />
        </v-flex>
        <v-card
          align="center"
          style="top: -10%"
          height="150"
          outlined
          class="hidden-md-and-up transparent"
        >
          <a class="nodec" href="tel:+962799451777">
            <v-btn
              width="200"
              class="
                mt-2
                pt-1
                cyan
                white--text
                text-transform-none
                call-btn
                artext
              "
              rounded
              depressed
              >اتصل الان!</v-btn
            >
          </a>
          <div>
            <br />
            <a
              class="nodec"
              target="_blank"
              href="https://www.facebook.com/ShamiDermaa/"
            >
              <v-icon
                x-large
                color="cyan"
                class="text-transform-none mx-2 artext"
                >mdi-facebook</v-icon
              >
            </a>
            <a
              class="nodec"
              target="_blank"
              href="https://www.instagram.com/shamiderma/"
            >
              <v-icon x-large color="cyan" class="mx-2">mdi-instagram</v-icon>
            </a>
          </div>
        </v-card>
      </v-card>
      <v-overlay :value="overlay" @click="overlay = false">
        <v-card
          color="#00abc8"
          class="rounded-card hidden-md-and-down"
          max-width="500"
          min-height="400"
        >
          <v-card outlined class="rounded-card transparent pa-8">
            <v-icon depressed @click="overlay = false" class="center6" dark
              >mdi-window-close</v-icon
            >
            <br />
            <Form valForm="HomePage"></Form>
          </v-card>
        </v-card>
        <v-card
          color="#00abc8"
          class="rounded-card hidden-md-and-up"
          max-width="500"
          height="346"
        >
          <v-card outlined class="rounded-card transparent pl-5 pr-5 pt-1 pb-0">
            <v-card
              style="top: 24px"
              align="center"
              outlined
              class="transparent"
            >
              <div class="hidden-md-and-up" style="al">
                <span style="font-size: 20px; line-height: 0px"
                  >Fill Your Information & <br />
                  We Will Contact You
                </span>
              </div>
            </v-card>
            <v-icon
              depressed
              @click="overlay = false"
              class="center6 hidden-md-and-down"
              dark
              >mdi-window-close</v-icon
            >

            <Form class="pl-8 pr-8" valForm="HomePage"></Form>
            <v-icon
              depressed
              @click="overlay = false"
              class="center6 hidden-md-and-up"
              style="top: -305px; left: -10px; font-size: 20px"
              dark
              >mdi-window-close
            </v-icon>
          </v-card>
        </v-card>
      </v-overlay>
    </div>
  </div>
</template>
<script>
import logo from "../assets/Logo.png";
import bg from "../assets/backgroundtest.png";
import texture from "../assets/Texture.png";
import tpic from "../assets/pic/Landing Photo.jpg";
import mpic from "../assets/pic/Landing Photo 2.jpg";
import ftspic from "../assets/pic/Facial Treatments 3.jpg";
import bcpic from "../assets/pic/Body Shaping Treatments 3.jpg";
import lhrpic from "../assets/pic/Beauty Treatments 3.jpg";
import ftspicm from "../assets/pic/Facial Treatments 4.jpg";
import bcpicm from "../assets/pic/Body Shaping Treatment 4.jpg";
import lhrpicm from "../assets/pic/Beauty Treatments 4.jpg";
import Form from "./Form.vue";

export default {
  name: "HelloWorld",
  components: {
    Form,
  },
  metaInfo() {
    return {
      title: "Shami Derma",
      meta: [
        {
          name: "description",
          content: "Shami Derma Jordan Beauty Center",
        },
      ],
    };
  },
  data: () => ({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    newHeight: 55,
    logo,
    bg,
    texture,
    tpic,
    mpic,
    overlay: false,
    originalText: localStorage.getItem("lang"),
    serviceCards: [
      {
        img: ftspic,
        imgm: ftspicm,
        click: false,
        style: "border-radius: 20px 20px; width: 100%;",
        textm: "Facial Treatments",
        textmar: "اجراءات الوجه",
        text: "Facial",
        texta: "اجراءات",
        text2: "Treatments",
        text2a: "الوجه",
        route: "/services/fts",
        extraText: "Caci Non-Surgical",
        extraText2: "Lifting Ultherapy treatment",
        extraText3: "Cat Eye Lifting",
        extraText4: "Botox  +  Fillers",
        classF: "justify-center pb-3 text-justify-center meticula-med ",
        classm:
          "greytext justify-center mx-3 ml-12 mt-7 pt-12 mb-0 pb-0 text-justify-center meticula-med",
        stylef:
          "padding-left: 80px; padding-right: 0px; padding-top: 100px; font-weight: lighter;",
        items: [
          { title: "HydraFacial", ro: "/services/fts/hydrafacial" },
          { title: "Microneedling", ro: "/services/fts/ml" },
          { title: "Diamond Peel", ro: "/services/fts/diamondpeel" },
          { title: "Crystal Peel", ro: "/services/fts/crystalpeel" },
          { title: "C02 Laser", ro: "/services/fts/co2laser" },
          { title: "Ultherapy", ro: "/services/fts/ultherapy" },
          { title: "Caci", ro: "/services/fts/caci" },
        ],
      },
      {
        img: bcpic,
        imgm: bcpicm,
        click: false,
        style: "border-radius: 20px 20px; width: 100%;",
        textm: "Body Shaping Treatments",
        textmar: "اجراءات نحت الجسم",
        text: "Body Shaping",
        texta: "اجراءات نحت",
        text2: "Treatments",
        text2a: "الجسم",
        route: "/services/bc",
        extraText: "Coolsculpting + Exima",
        extraText2: "",
        classm:
          "greytext justify-center  mr-12 mt-7 pt-12 mb-0 pb-0 text-justify-center meticula-med",
        classF:
          "mt-12 pt-12 pr-12 mr-12 justify-center pb-3 text-justify-center meticula-med ",
        stylef:
          "padding-left: 0px; padding-left: -100px; padding-right: 200px; padding-top: 100px; font-weight: lighter; ",
        items: [
          { title: "Coolsculpting", ro: "/services/bc/coolsculpting" },
          { title: "Eximia", ro: "/services/bc/exima" },
        ],
      },
      {
        img: lhrpic,
        imgm: lhrpicm,
        click: false,
        style: "border-radius: 20px 20px; width: 100%;",
        textm: "Beauty Treatments",
        textmar: "اجراءات التجميلية",
        text: "Beauty",
        texta: "اجراءات",
        text2: "Treatments",
        text2a: "التجميلة",
        route: "/services/bt",
        extraText: "Laster Hair Removal",
        classm:
          "greytext justify-center mx-3 ml-12 mt-7 pt-12 mb-0 pb-0 text-justify-center meticula-med",
        classF:
          "mt-12 pt-12 pl-12 ml-12 justify-center pb-3 text-justify-center meticula-med ",
        stylef:
          "padding-left: 80px; padding-right: 0px; padding-top: 100px; font-weight: lighter;",
        items: [
          { title: "Laser Hair Removal", ro: "/services/bt/lhr" },
          { title: "Red Carpet", ro: "/services/bt/rc" },
          { title: "ProFhilo", ro: "/services/bt/pf" },
          { title: "Mesotherapy", ro: "/services/bt/mt" },
          { title: "Anti Wrinkle", ro: "/services/bt/aw" },
          { title: "Dermal Fillers", ro: "/services/bt/df" },
          { title: "PRP", ro: "/services/bt/prp" },
        ],
      },
    ],
  }),
  methods: {
    getCol() {
      if (this.windowWidth < 1200) {
        return 4;
      } else {
        return 3;
      }
    },

    isiten() {
      if (this.originalText == "EN") {
        return true;
      } else {
        return false;
      }
    },

    getClassText() {
      if (this.windowWidth < 1200) {
        return "cyan white--text text-transform-none title";
      } else {
        return "cyan white--text text-transform-none display-1";
      }
    },
    getClassText2() {
      if (this.windowWidth < 1200) {
        return "transparent meticula-small text-weight-bold";
      } else {
        return "my-style transparent";
      }
    },
    getCard1() {
      if (this.windowWidth < 1200) {
        return false;
      } else {
        return true;
      }
    },
    getClassBtn() {
      if (this.windowWidth < 1200) {
        return "center5 text-transform-none meticula ";
      } else {
        return "text-transform-none meticula-small";
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    mOrT() {
      if (this.isMobile()) {
        return this.mpic;
      } else {
        return this.tpic;
      }
    },
    getHeight() {
      if (this.isMobile()) {
        return 400;
      } else {
        return this.windowHeight * 0.9;
      }
    },
    getCP() {
      if (this.isMobile()) {
        return "pt-12 mt-12";
      } else {
        return "";
      }
    },
    styleCard() {
      if (this.isMobile()) {
        return "top: -50px";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.artext {
  font-family: ar !important;
}

.meticula {
  font-family: meticula;
  font-size: calc(11px + 1vw);
}

.meticula-med {
  font-family: meticula;
  font-size: calc(1.8rem);
  color: #707070;
  text-decoration: none !important;
}

.meticula-title {
  font-family: meticula;
  font-size: calc(30px + 1vw);
}

.meticula-title-m {
  font-family: meticula;

  font-size: calc(20px + 1.5vw);
  left: -20%;
  color: #808080;
}

.meticula-big {
  font-family: meticula;
  font-size: calc(20px + 1.9vw);
}

.meticula-small {
  font-family: meticula;
  font-size: 26px;
}

.meticula-small-btn {
  font-family: meticula;
  font-size: 12px;

  color: white;
  letter-spacing: 0.5px;
  word-spacing: -2px;
}

.meticula-medium {
  font-family: meticula;
  font-size: calc(20px + 1rem);
  font-weight: lighter;
  letter-spacing: 0.5px;
}

.my-style {
  font-family: meticula;
  font-size: calc(20px + 3.9vw);
}

.call-btn {
  font-family: meticula;
  font-size: 1.2rem;
}

.my-otherstyle {
  font-family: meticula;
  font-size: 26px;
}

.text-transform-none {
  text-transform: none !important;
  font-family: meticula;
  letter-spacing: 0.5px;
  opacity: 1;
}

.paraop {
  opacity: 0.3;
}

.slideX-enter-active,
.slideX-leave-active {
  transition: 1s;
}

.slideX-enter,
.slideX-leave {
  transform: translateX(-50vh);
}

.slideX2-enter-active,
.slideX2-leave-active {
  transition: 1s;
}

.slideX2-enter,
.slideX2-leave {
  transform: translateX(50vh);
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: 1s;
}

.slideUp-enter,
.slideUp-leave {
  transform: translateY(50vh);
}

.center1 {
  margin: auto;
  width: 15%;

  padding: 10px;
}

.center2 {
  margin: auto;
  width: 27%;

  padding: 10px;
}

.center5 {
  margin: auto;
  width: 100%;

  padding: 10px;
}

.center9 {
  left: 23%;
}

.greytext {
  color: #464646;
  line-height: 110%;
}

.greytext2 {
  color: #808080;
}

.btn-round {
  border-radius: 50px;
}

.nodec {
  text-decoration: none;
}

.rounded-card {
  border-radius: 50px;
}

.center6 {
  margin-left: 95.5%;
  margin-top: -5%;
}

.roundbrd {
  border-radius: 50px;
}
</style>
