<template>
  <div>
    <v-footer v-if="isiten()" color="#CACACA">
      <v-card class="transparent" outlined>
        <br />
        <v-row style="width: 100vw" justify="center">
          <v-col
            v-for="(item, index) in footCard"
            class="mx-5"
            :key="index"
            :cols="scrnSize()"
          >
            <v-card outlined :style="item.stylec" class="transparent">
              <div
                class="
                  meticula-medium
                  pt-2
                  pb-2
                  pl-1
                  grey--text
                  text--darken-3
                  preSpace
                "
              >
                {{ item.text }}
              </div>
              <v-card
                width="120"
                outlined
                v-if="item.vbtn"
                class="pl-2 meticula-btn justify-center transparent"
                align="center"
              >
                <v-btn
                  rounded
                  min-width="112"
                  height="28"
                  align="center"
                  outlined
                  v-if="item.vbtn"
                  :href="item.route"
                  :class="item.vbtnopt"
                  >{{ item.btnText }}</v-btn
                >
              </v-card>

              <div
                class="
                  pl-2
                  meticula-small
                  font-weight-bold
                  grey--text
                  text--darken-3
                "
              >
                {{ item.subTitle }}
              </div>

              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.subtText }}
              </div>
              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.timeText }}
              </div>
              <div class="pl-2 meticula-small pb-2 grey--text text--darken-3">
                {{ item.subText }}
              </div>
              <div
                class="
                  pl-2
                  meticula-small
                  font-weight-bold
                  grey--text
                  text--darken-3
                "
              >
                {{ item.subTitle2 }}
              </div>

              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.subText2 }}
                <v-btn
                  outlined
                  v-if="item.vbtn2"
                  :class="item.vbtnopt2"
                  :to="item.route2"
                  >{{ item.btnText2 }}</v-btn
                >
              </div>
              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.timeText2 }}
              </div>
              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.subText3 }}
              </div>
              <div class="pl-2 meticula-small pb-2 grey--text text--darken-3">
                {{ item.subText6 }}
              </div>
              <div
                class="
                  pl-2
                  meticula-small
                  font-weight-bold
                  grey--text
                  text--darken-3
                "
              >
                {{ item.subTitle3 }}
              </div>
              <div class="pl-2 meticula-small grey--text text--darken-3 artext">
                {{ item.subText4 }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
    <v-footer v-if="!isiten()" color="#CACACA">
      <v-card class="transparent" outlined>
        <br />
        <v-row style="width: 100vw" justify="center">
          <v-col
            v-for="(item, index) in footCard"
            class="mx-5"
            :key="index"
            :cols="scrnSize()"
          >
            <v-card outlined :style="item.stylec" class="transparent">
              <div
                class="
                  meticula-medium
                  pt-2
                  pb-2
                  pl-1
                  grey--text
                  text--darken-3
                  preSpace
                  artext
                "
              >
                {{ item.texta }}
              </div>
              <v-card
                width="120"
                outlined
                v-if="item.vbtn"
                class="pl-2 meticula-btn justify-center transparent artext"
                align="center"
              >
                <v-btn
                  rounded
                  min-width="112"
                  height="28"
                  align="center"
                  outlined
                  v-if="item.vbtn"
                  :href="item.route"
                  :class="item.vbtnopt + ' artext'"
                  >{{ item.btnTexta }}</v-btn
                >
              </v-card>

              <div
                class="
                  pl-2
                  meticula-small
                  font-weight-bold
                  grey--text
                  text--darken-3
                  artext
                "
              >
                {{ item.subTitlea }}
              </div>

              <div class="pl-2 meticula-small grey--text text--darken-3 artext">
                {{ item.subtTexta }}
              </div>
              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.timeText }}
              </div>
              <div
                class="
                  pl-2
                  meticula-small
                  pb-2
                  grey--text
                  text--darken-3
                  artext
                "
              >
                {{ item.subTexta }}
              </div>
              <div
                class="
                  pl-2
                  meticula-small
                  font-weight-bold
                  grey--text
                  text--darken-3
                  artext
                "
              >
                {{ item.subTitle2a }}
              </div>

              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.subText2 }}
                <v-btn
                  outlined
                  v-if="item.vbtn2"
                  :class="item.vbtnopt2"
                  :to="item.route2"
                  >{{ item.btnText2 }}</v-btn
                >
              </div>
              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.timeText2 }}
              </div>
              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.subText3 }}
              </div>
              <div class="pl-2 meticula-small pb-2 grey--text text--darken-3">
                {{ item.subText6 }}
              </div>
              <div
                class="
                  pl-2
                  meticula-small
                  font-weight-bold
                  grey--text
                  text--darken-3
                  artext
                "
              >
                {{ item.subTitle3a }}
              </div>
              <div class="pl-2 meticula-small grey--text text--darken-3">
                {{ item.subText4 }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    footCard: [
      {
        text: "CONTACT US",
        texta: "اتصل بنا",
        subTitle: "Address:",
        subTitlea: "العنوان:",
        subText: "7th Circle, Amman, Jordan",
        subTexta: "الدوار السابع ، عمان ، الأردن",
        subTitle2: "Phone:",
        subTitle2a: "هاتف:",
        subText2: "+962 6 5822626",
        subText3: "+962 79 9451777",
        subText6: "+962 79 7676762",
        subTitle3: "E-mail:",
        subTitle3a: "البريد الإلكتروني:",
        subText4: "Info@shamiderma.com",
        vbtnopt2: "transparent meticula-small",
        vbtnopt: "transparent meticula-small",
        stylec: "left: 5vw;",
      },
      {
        text: "OPENING HOURS",
        texta: "ساعات العمل",
        subtText: "Saturday - Wednesday",
        subtTexta: "السبت - الأربعاء",
        timeText: "9:00 AM - 5:00 PM",
        subText2: "الخميس",
        timeText2: "9:00 AM - 2:00 PM",
        vbtnopt: "transparent meticula-small",
        vbtnopt2: "transparent meticula-small",
        stylec: "left: 2.5vw;",
      },
      {
        text: "Appointment",
        texta: "موعد",
        btnText: "Call Now!",
        btnTexta: "اتصل الان!",
        route: "/contact",
        vbtn: true,
        vbtnopt: "text-transform-none meticula-btn pl-4 mr-1",
        vic: true,
        stylec: "left: 2vw;",
      },
    ],
  }),
  methods: {
    scrnSize() {
      if (window.innerWidth < 1200) {
        return 12;
      } else {
        return 2;
      }
    },
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.my-style {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 30px;
}

.preSpace {
  white-space: pre;
}

.meticula-big {
  font-family: meticula;
  font-size: 70px;
}

.meticula-small {
  font-family: meticula;
  font-size: calc(14px);
}

.meticula-medium {
  font-family: meticula;
  font-size: calc(16px);
}

.meticula-btn {
  font-family: meticula;
  font-size: calc(15px);
  letter-spacing: 0;
}

.text-transform-none {
  text-transform: none !important;
}

.center1 {
  margin: auto;
  width: 15%;

  padding: 10px;
}

.artext {
  font-family: ar;
}
</style>
