<style>
html.is-rtl * {
  direction: rtl;
}
</style>
<template>
  <v-app>
    <div v-if="isiten()">
      <v-app-bar
        color="white accent-4"
        class="hidden-md-and-up stickyb"
        elevate-on-scroll
      >
        <router-link depressed to="/">
          <v-img width="136" height="25" :src="logo"></v-img>
        </router-link>
        <v-spacer></v-spacer>
        <div
          id="divar"
          style="
            border-radius: 50% !important;
            border-style: solid;
            border-color: #00bcd4;
            width: 30px;
            height: 30px;
            margin-left: 10px;
          "
          @click="cacheLang()"
        >
          <v-card
            style="
              position: relative;
              top: 1px;
              left: 1px;
              color: #00bcd4 !important;
            "
            outlined
            :class="colorbtn4"
            color="#00abc8"
            @mouseenter="
              colorbtn4 =
                'cyan--text meticula-small font-weight-regular transparent '
            "
            @mouseleave="
              colorbtn4 =
                'grey--text meticula-small font-weight-regular transparent '
            "
            >AR</v-card
          >
        </div>
        <v-app-bar-nav-icon
          color="cyan"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </v-app-bar>
      <v-card
        height="25"
        outlined
        class="transparent hidden-md-and-up"
      ></v-card>

      <v-navigation-drawer
        class="hidden-md-and-up stickyb bo-red"
        v-model="drawer"
        absolute
        right
        temporary
        height="280"
        color="#00abc8"
        style="top: 56px"
        width="200"
      >
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            class="pl-1 pb-2"
            active-class="deep-purple--text text--accent-4"
          >
            <br />
            <br />
            <br />
            <v-card class="ml-3 transparent" outlined>
              <router-link
                to="/"
                class="
                  white--text
                  text--darken-1
                  meticula-med
                  font-weight-regular
                  transparent
                  no-dec
                "
                >HOME</router-link
              >
            </v-card>
            <br />

            <v-card class="ml-3 transparent" outlined>
              <router-link
                to="/about"
                class="
                  white--text
                  text--darken-1
                  meticula-med
                  font-weight-regular
                  transparent
                  no-dec
                "
                >ABOUT US</router-link
              >
            </v-card>

            <br />

            <v-card class="mx-3 transparent" outlined>
              <router-link
                to="/services"
                class="
                  white--text
                  text--darken-1
                  meticula-med
                  font-weight-regular
                  transparent
                  no-dec
                "
                >OUR SERVICES</router-link
              >
            </v-card>

            <br />

            <v-card class="mx-3 transparent" outlined>
              <router-link
                to="/contact"
                class="
                  white--text
                  text--darken-1
                  meticula-med
                  font-weight-regular
                  transparent
                  no-dec
                "
                >CONTACT US</router-link
              >
            </v-card>

            <!-- <v-list-item>
              <v-btn
                class="grey--text text--darken-1 meticula-medium font-weight-regular transparent"
                depressed
                color="#00abc8"
              >text</v-btn>
          </v-list-item>-->
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-toolbar class="hidden-sm-and-down" height="100" flat>
        <router-link depressed to="/">
          <v-img class="mt-2 ml-8" width="224" height="41" :src="logo"></v-img>
        </router-link>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <router-link
            to="/about"
            text
            color="#00abc8"
            class="hidden-sm-and-down textcol"
          >
            <v-card
              align="center"
              outlined
              :class="colorbtn"
              color="#00abc8"
              @mouseenter="
                colorbtn =
                  'cyan--text meticula-small font-weight-regular transparent ma-auto py-9'
              "
              @mouseleave="
                colorbtn =
                  'grey--text meticula-small font-weight-regular transparent ma-auto py-9'
              "
              >ABOUT US</v-card
            >
          </router-link>
          <router-link
            to="/services"
            class="
              hidden-sm-and-down
              textcol
              text--darken-1
              meticula-small
              font-weight-regular
              transparent
              mx-9
            "
          >
            <v-card
              align="center"
              outlined
              :class="colorbtn3"
              color="#00abc8"
              @mouseenter="
                colorbtn3 =
                  'cyan--text meticula-small font-weight-regular transparent ma-auto mx-5 py-9'
              "
              @mouseleave="
                colorbtn3 =
                  'grey--text meticula-small font-weight-regular transparent ma-auto mx-5 py-9'
              "
              >OUR SERVICES</v-card
            >
          </router-link>

          <router-link
            to="/contact"
            text
            color="#00abc8"
            class="
              hidden-sm-and-down
              textcol
              text--darken-1
              meticula-small
              ma-auto
              font-weight-regular
              transparent
              mr-3
            "
          >
            <v-card
              align="center"
              height="100"
              justify="center"
              outlined
              :class="colorbtn2"
              color="#00abc8"
              @mouseenter="
                colorbtn2 =
                  'cyan--text meticula-small font-weight-regular transparent ma-auto py-9 mr-5'
              "
              @mouseleave="
                colorbtn2 =
                  'grey--text meticula-small font-weight-regular transparent ma-auto py-9 mr-5'
              "
              >CONTACT</v-card
            >
          </router-link>
          <router-link
            :to="locationLang()"
            text
            color="#00abc8"
            class="
              hidden-sm-and-down
              textcol
              text--darken-1
              meticula-small
              ma-auto
              font-weight-regular
              transparent
              mr-3
            "
          >
            <div
              id="divar"
              style="
                border-radius: 50% !important;
                border-style: solid;
                border-color: grey;
                width: 75px;
                height: 75px;
              "
              @click="cacheLang()"
            >
              <v-card
                style="position: relative; top: 22px; left: 19px"
                outlined
                :class="colorbtn4"
                color="#00abc8"
                @mouseenter="
                  colorbtn4 =
                    'cyan--text meticula-small font-weight-regular transparent '
                "
                @mouseleave="
                  colorbtn4 =
                    'grey--text meticula-small font-weight-regular transparent '
                "
                >AR</v-card
              >
            </div>
          </router-link>
        </v-toolbar-items>
      </v-toolbar>

      <v-main>
        <router-view></router-view>
      </v-main>

      <Footer class="hidden-sm-and-down" />
      <v-card
        tile
        flat
        align="center"
        class="bgc meticula-small hidden-md-and-up"
        >Powered By Adverby</v-card
      >
      <v-card
        color="#CACACA"
        flat
        tile
        align="right"
        class="meticula-small-2 hidden-sm-and-down"
        >Powered By Adverby</v-card
      >
    </div>

    <div style="direction: rtl; font-family: ar !important" v-if="!isiten()">
      <v-app-bar
        color="white accent-4"
        class="hidden-md-and-up stickyb"
        elevate-on-scroll
        style=""
      >
        <router-link depressed to="/">
          <v-img width="136" height="25" :src="logo"></v-img>
        </router-link>
        <v-spacer></v-spacer>
        <div
          id="divar"
          style="
            border-radius: 50% !important;
            border-style: solid;
            border-color: #00bcd4;
            width: 30px;
            height: 30px;
            margin-left: 10px;
          "
          @click="cacheLang()"
        >
          <v-card
            style="
              position: relative;
              top: 1px;
              right: 1px;
              color: #00bcd4 !important;
            "
            outlined
            :class="colorbtn4"
            color="#00abc8"
            @mouseenter="
              colorbtn4 =
                'cyan--text meticula-small font-weight-regular transparent '
            "
            @mouseleave="
              colorbtn4 =
                'grey--text meticula-small font-weight-regular transparent '
            "
            >EN</v-card
          >
        </div>
        <v-app-bar-nav-icon
          color="cyan"
          @click.stop="drawer = !drawer"
        ></v-app-bar-nav-icon>
      </v-app-bar>
      <v-card
        height="25"
        outlined
        class="transparent hidden-md-and-up"
      ></v-card>

      <v-navigation-drawer
        class="hidden-md-and-up stickyb bo-red"
        v-model="drawer"
        absolute
        right
        temporary
        height="280"
        color="#00abc8"
        style="top: 56px"
        width="200"
      >
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            class="pl-1 pb-2"
            active-class="deep-purple--text text--accent-4"
          >
            <br />
            <br />
            <br />
            <v-card class="ml-3 transparent" outlined>
              <router-link
                to="/"
                class="
                  white--text
                  text--darken-1
                  meticula-med
                  font-weight-regular
                  transparent
                  no-dec
                  artext
                "
                >الصفحة الرئيسية</router-link
              >
            </v-card>
            <br />

            <v-card class="ml-3 transparent" outlined>
              <router-link
                to="/about"
                class="
                  white--text
                  text--darken-1
                  meticula-med
                  font-weight-regular
                  transparent
                  no-dec
                  artext
                "
                >معلومات عنا</router-link
              >
            </v-card>

            <br />

            <v-card class="mx-3 transparent" outlined>
              <router-link
                to="/services"
                class="
                  white--text
                  text--darken-1
                  meticula-med
                  font-weight-regular
                  transparent
                  no-dec
                  artext
                "
                >خدماتنا</router-link
              >
            </v-card>

            <br />

            <v-card class="mx-3 transparent" outlined>
              <router-link
                to="/contact"
                class="
                  white--text
                  text--darken-1
                  meticula-med
                  font-weight-regular
                  transparent
                  no-dec
                  artext
                "
                >اتصل بنا</router-link
              >
            </v-card>

            <!-- <v-list-item>
              <v-btn
                class="grey--text text--darken-1 meticula-medium font-weight-regular transparent"
                depressed
                color="#00abc8"
              >text</v-btn>
          </v-list-item>-->
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-toolbar class="hidden-sm-and-down" height="100" flat>
        <router-link depressed to="/">
          <v-img class="mt-2 ml-8" width="224" height="41" :src="logo"></v-img>
        </router-link>

        <v-spacer></v-spacer>
        <v-toolbar-items>
          <router-link
            to="/about"
            text
            color="#00abc8"
            class="hidden-sm-and-down textcol"
          >
            <v-card
              align="center"
              outlined
              :class="colorbtn + ' artext'"
              color="#00abc8"
              @mouseenter="
                colorbtn =
                  'cyan--text meticula-small font-weight-regular transparent ma-auto py-9 artext'
              "
              @mouseleave="
                colorbtn =
                  'grey--text meticula-small font-weight-regular transparent ma-auto py-9 artext'
              "
              >معلومات عنا</v-card
            >
          </router-link>
          <router-link
            to="/services"
            class="
              hidden-sm-and-down
              textcol
              text--darken-1
              meticula-small
              font-weight-regular
              transparent
              mx-9
              artext
            "
          >
            <v-card
              align="center"
              outlined
              :class="colorbtn3 + ' artext'"
              color="#00abc8"
              @mouseenter="
                colorbtn3 =
                  'cyan--text meticula-small font-weight-regular transparent ma-auto mx-5 py-9 artext'
              "
              @mouseleave="
                colorbtn3 =
                  'grey--text meticula-small font-weight-regular transparent ma-auto mx-5 py-9 artext'
              "
              >خدماتنا</v-card
            >
          </router-link>

          <router-link
            to="/contact"
            text
            color="#00abc8"
            class="
              hidden-sm-and-down
              textcol
              text--darken-1
              meticula-small
              ma-auto
              font-weight-regular
              transparent
              mr-3
              artext
            "
          >
            <v-card
              align="center"
              height="100"
              justify="center"
              outlined
              :class="colorbtn2ar + ' artext'"
              color="#00abc8"
              @mouseenter="
                colorbtn2ar =
                  'cyan--text meticula-small font-weight-regular transparent ml-5 py-9 artext'
              "
              @mouseleave="
                colorbtn2ar =
                  'grey--text meticula-small font-weight-regular transparent ml-5 py-9 artext'
              "
              >اتصل بنا</v-card
            >
          </router-link>
          <router-link
            :to="locationLang()"
            text
            color="#00abc8"
            class="
              hidden-sm-and-down
              textcol
              text--darken-1
              meticula-small
              ma-auto
              font-weight-regular
              transparent
              mr-3
            "
          >
            <div
              id="divar"
              style="
                border-radius: 50% !important;
                border-style: solid;
                border-color: grey;
                width: 75px;
                height: 75px;
              "
              @click="cacheLang()"
            >
              <v-card
                style="position: relative; top: 22px; right: 19px"
                outlined
                :class="colorbtn4"
                color="#00abc8"
                @mouseenter="
                  colorbtn4 =
                    'cyan--text meticula-small font-weight-regular transparent '
                "
                @mouseleave="
                  colorbtn4 =
                    'grey--text meticula-small font-weight-regular transparent '
                "
                >EN</v-card
              >
            </div>
          </router-link>
        </v-toolbar-items>
      </v-toolbar>

      <v-main>
        <router-view></router-view>
      </v-main>

      <Footer class="hidden-sm-and-down" />
      <v-card
        tile
        flat
        align="center"
        class="bgc meticula-small hidden-md-and-up"
        >Powered By Adverby</v-card
      >
      <v-card
        color="#CACACA"
        flat
        tile
        align="right"
        class="meticula-small-2 hidden-sm-and-down"
        >Powered By Adverby</v-card
      >
    </div>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import Footer from "./components/Footer";
import logo from "./assets/Logo.png";
// import Navbar from "./components/Navbar.vue";
function firstLang() {
  if (localStorage.getItem("lang")) {
    return;
  } else {
    localStorage.setItem("lang", "EN");
  }
}

firstLang();
export default {
  name: "App",

  components: {
    // HelloWorld,
    Footer,
    // Navbar,
  },

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Derma",
    // all titles will be injected into this template
    titleTemplate: "%s",
  },

  data: () => ({
    //
    logo,
    windowWidth: window.innerWidth,
    drawer: false,
    group: null,
    colorbtn: "grey--text meticula-small font-weight-regular transparent  py-9",
    colorbtn2:
      "grey--text meticula-small font-weight-regular transparent  py-9 mr-5",
    colorbtn2ar:
      "grey--text meticula-small font-weight-regular transparent py-9 ml-5",
    colorbtn3:
      "grey--text meticula-small font-weight-regular transparent  py-9 mx-5",
    colorbtn4: "grey--text meticula-small font-weight-regular transparent  ",
    originalText: localStorage.getItem("lang"),
  }),
  methods: {
    getCol() {
      if (this.windowWidth > 1200) {
        return true;
      } else {
        return false;
      }
    },

    cacheLang() {
      if (localStorage.getItem("lang") == "EN") {
        localStorage.setItem("lang", "AR");
        document.querySelector("html").classList.add("is-rtl");
        window.setTimeout(location.reload(), 5000);
      } else {
        document.querySelector("html").classList.remove("is-rtl");
        localStorage.setItem("lang", "EN");
        window.setTimeout(location.reload(), 5000);
      }
    },

    locationLang() {
      if (localStorage.getItem("lang") == "EN") {
        return "/ar";
      } else {
        return "/";
      }
    },

    isiten() {
      if (this.originalText == "EN") {
        return true;
      } else {
        return false;
      }
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
<style scoped>
.meticula-big {
  font-family: meticula;
  font-size: 70px;
}

.meticula-small {
  font-family: meticula;
  font-size: calc(12px + 0.5vw);
}
.meticula-small-2 {
  font-family: meticula;
}

.meticula-med {
  font-family: meticula;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
}

.meticula-medium {
  font-family: meticula;
  font-size: 50px;
}

.artext {
  font-family: ar !important;
}

.stickyb {
  position: fixed;
  width: 100%;
  z-index: 5;
}

.textcol {
  color: #00abc8;
  text-decoration: none;
}

.bo-red {
  border-bottom-left-radius: 12%;
}

.no-dec {
  text-decoration: none;
  line-height: 10%;
}

.bgc {
  background-color: #cacaca;
}

#divar {
  transition: 0.3s;
}

#divar:hover {
  border-color: #00bcd4 !important;
}

#divar:hover > * {
  color: #00bcd4 !important;
}

#divar > * {
  transition: 0.3s;
}
</style>
