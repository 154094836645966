<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",

  metaInfo: {
    title: "Shami Derma",
    meta: [
      {
        name: "description",
        content: "Shami Derma Amman, Jordan ",
      },
    ],
  },
  components: {
    HelloWorld,
  },
};
</script>
