import "./assets/main.css";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import Meta from "vue-meta";
import VueCarousel from "vue-carousel";

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  Vuelidate,
  VueCarousel,
  render: (h) => h(App),
}).$mount("#app");

Vue.use(Meta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  ssrAttribute: "data-vue-meta-server-rendered",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: true,
});
